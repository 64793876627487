h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
}

p, a, li, span {
  font-family: 'Merriweather', serif;
}

.hero-background {
  background-image: url('./assets/bg-img\ copy.jpg');
  background-size: cover;
  background-repeat: none;
  width: 100%;
  height: 750px;
}

.hero-container{
  width: 100%;
  padding-top: 175px;
}

ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 1% 5%;
  margin: 1.5% 0;
  border-radius: 7px;
  transition: 0.3s;
}

ul li p {
  font-weight: 300;
  color: #D7DDE8;
  margin-bottom: 0;
}

ul li a {
  position: relative; 
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 52px;
  background: #333;
  border-radius: 50%;
  font-size: 25px;
  color: #666;
  transition: .5s;
}

ul li a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #D7DDE8;
  transition: .5s;
  transform: scale(.9);
  z-index: -1;
}

ul li a:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #D7DDE8;
}

ul li a:hover {
  color: #D7DDE8;
  box-shadow: 0 0 15px #D7DDE8;
  text-shadow: 0 0 0px #D7DDE8;
}
